// ==========================================================================
// Base
// ==========================================================================

// init Isotope
    var $grid = $(".CardFilterBlock__Grid");

    $grid.isotope({
        itemSelector: '.CardFilterBlock__Item',
        layoutMode: "masonry",
    });

  $grid.imagesLoaded( function() {
        $grid.isotope('layout');
    });
// store filter for each group
var filters = {};

$('.CardFilterBlock__Filters').on('change', function (event) {
    var $select = $(event.target);
    // get group key
    var filterGroup = $select.attr('value-group');
    // set filter for group
    filters[filterGroup] = event.target.value;
    // combine filters
    var filterValue = concatValues(filters);
    // set filter for Isotope
    $grid.isotope({filter: filterValue});
});

// flatten object by concatting values
function concatValues(obj) {
    var value = '';
    for (var prop in obj) {
        value += obj[prop];
    }
    return value;
}
