// Initialize Hero Slider
var swiperhero = new Swiper(".heroSliderSwiper", {

    // Selectable 
    //simulateTouch: false,

    // Direction
    direction: "vertical",

    // Pagination
    pagination: {
        el: ".hero-swiper-pagination",
        clickable: true,
    },

});


// Initialize Logo Slider
var swiperlogo = new Swiper(".logoCarouselSwiper", {

    // Number of logos to show
    slidesPerView: 2,
    breakpoints: {
        640: { slidesPerView: 3, },
        768: { slidesPerView: 4 },
        1024: { slidesPerView: 5 }
    },

    // Show cursor
    grabCursor: true,

    // Loop the carousel
    loop: true,

    // Pagination
    pagination: {
        el: ".logo-swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },

    // Slide duration
    speed: 500,

});

// Initialize Slider Block
var swiperslider = new Swiper(".SliderBlock", {

    // Direction
    direction: "horizontal",

    // Loop the Slider
    loop: true,

    parallax: true,

    // Pagination
    pagination: {
        el: ".slider-swiper-pagination",
        clickable: true,
    },

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});
