// Initialize Hero Slider
var swiperhero = new Swiper(".heroSliderSwiper", {

    // Selectable 
    //simulateTouch: false,

    // Direction
    direction: "vertical",

    // Pagination
    pagination: {
        el: ".hero-swiper-pagination",
        clickable: true,
    },

});


// Initialize Logo Slider
var swiperlogo = new Swiper(".logoCarouselSwiper", {

    // Number of logos to show
    slidesPerView: 2,
    breakpoints: {
        640: { slidesPerView: 3, },
        768: { slidesPerView: 4 },
        1024: { slidesPerView: 5 }
    },

    // Show cursor
    grabCursor: true,

    // Loop the carousel
    loop: true,

    // Pagination
    pagination: {
        el: ".logo-swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },

    // Slide duration
    speed: 500,

});

// Initialize Slider Block
var swiperslider = new Swiper(".SliderBlock", {

    // Direction
    direction: "horizontal",

    // Loop the Slider
    loop: true,

    parallax: true,

    // Pagination
    pagination: {
        el: ".slider-swiper-pagination",
        clickable: true,
    },

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});



// Initialize Swipers only if there are multiple slides
document.addEventListener('DOMContentLoaded', function () {
    console.log('test');
    
    // Function to initialize a Swiper if there are multiple slides
    function initializeSwiper(swiperClass) {
        var swiperContainer = document.querySelector(swiperClass);
        if (swiperContainer) {
            var slides = swiperContainer.querySelectorAll('.swiper-slide');
            if (slides.length > 1) {
                return new Swiper(swiperClass, {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    loop: true,

                    // Autoplay configuration
                    autoplay: {
                        delay: 5000, // 5 seconds
                        disableOnInteraction: false,
                    },

                    // Pagination
                    pagination: {
                        el: `${swiperClass} .swiper-pagination`,
                        clickable: true,
                    },

                });
            }
        }
        return null;
    }

    // Initialize Secondary Swipers
    var swiperSecondary1 = initializeSwiper('.SecondarySwiper-1');
    var swiperSecondary2 = initializeSwiper('.SecondarySwiper-2');

    // Pause autoplay on hover and resume on mouse leave for Swiper 1
    if (swiperSecondary1) {
        var secondarySwiper1Container = document.querySelector('.SecondarySwiper-1');
        secondarySwiper1Container.addEventListener('mouseenter', function () {
            swiperSecondary1.autoplay.stop();
        });
        secondarySwiper1Container.addEventListener('mouseleave', function () {
            swiperSecondary1.autoplay.start();
        });
    }

    // Pause autoplay on hover and resume on mouse leave for Swiper 2
    if (swiperSecondary2) {
        var secondarySwiper2Container = document.querySelector('.SecondarySwiper-2');
        secondarySwiper2Container.addEventListener('mouseenter', function () {
            swiperSecondary2.autoplay.stop();
        });
        secondarySwiper2Container.addEventListener('mouseleave', function () {
            swiperSecondary2.autoplay.start();
        });
    }

});
